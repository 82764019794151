<script setup>
import GoogleButton from './Partials/GoogleButton.vue';
import {onMounted, ref} from 'vue';
import {route as routeFn} from 'ziggy-js';
import {router, usePage} from '@inertiajs/vue3';
import {Checkbox, FormField} from '@/Components/Form';
import {PasswordInput, TextInput} from '@/Components/Frontend/Form';
import {AnchorLink} from '@/Components/Frontend/Navigation';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import AsideContent from '@/Layouts/Partials/AsideContent.vue';
import {scrollToFirstErrorMessage} from '@/utils/scroll-to';
import {EnvelopeIcon} from '@heroicons/vue/24/outline';
import {trans as t} from 'laravel-vue-i18n';
import {useForm} from 'vee-validate';
import * as yup from 'yup';

defineProps({
    canResetPassword: {
        type: Boolean,
        default: false,
    },
    canRegister: {
        type: Boolean,
        default: usePage()?.props?.canRegister,
    },
    canSso: {
        type: Boolean,
        default: usePage()?.props?.feature_flags?.sso ?? false,
    },
    status: {
        type: String,
        default: null,
    },
});
const isAdminLogin = ref(undefined);

const schema = yup.object({
    email: yup.string().required(t('Required field')).email(t('Please enter a valid Email')),
    password: yup.string().required(t('Required field')),
});

const form = useForm({
    initialValues: {
        email: '',
        password: '',
        remember: false,
    },
    validationSchema: schema,
});

const submitForm = form.handleSubmit((values) => {
    router.post(
        routeFn('login'),
        {...values, remember: values.remember ? 'on' : ''},
        {
            preserveState: 'errors',
            preserveScroll: true,
            onSuccess: () => {},
            onError: (errors) => {
                form.resetField('password');
                form.setErrors({
                    email: errors.email,
                    password: errors.password,
                });
                scrollToFirstErrorMessage();
            },
        },
    );
}, scrollToFirstErrorMessage);

onMounted(() => {
    const searchParams = new URLSearchParams(window.location.search);

    isAdminLogin.value = searchParams.get('admin') === 'yes';
});
</script>

<template>
    <AuthLayout>
        <template #aside-content>
            <AsideContent
                :layout="'auth'"
                :title="$t('Talent acquisition platform')"
                :description="$t('The natural union between talents & organizations')" />
        </template>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <section class="absolute w-[90%] md:w-full top-1/4 left-1/2 -translate-x-1/2 mx-auto">
            <form @submit="submitForm">
                <div class="flex flex-col gap-4 mb-16">
                    <span class="text-3xl font-light text-primary">{{ $t('Login') }}</span>
                    <span>{{ $t('Scale your team today!') }}</span>
                </div>
                <div class="flex flex-col gap-6">
                    <div>
                        <FormField :error-message="form.errors.value.email" name="email" :required="true">
                            <TextInput
                                id="email"
                                autofocus
                                autocomplete="username"
                                name="email"
                                :placeholder="`${$t('Company email')}`"
                                required>
                                <template #left> <EnvelopeIcon class="size-6" /> </template>
                            </TextInput>
                        </FormField>
                    </div>

                    <div>
                        <FormField :error-message="form.errors.value.password" name="password" :required="true">
                            <PasswordInput
                                id="password"
                                name="password"
                                type="password"
                                :placeholder="`${$t('Password')}`"
                                autocomplete="current-password"
                                required />
                        </FormField>
                    </div>
                </div>

                <div class="flex justify-between mt-4">
                    <label class="flex items-center">
                        <Checkbox :styled="remember" name="remember" />
                        <span class="ms-2 text-sm">{{ $t('Remember me') }}</span>
                    </label>
                    <AnchorLink
                        v-if="canResetPassword"
                        :inertia="true"
                        :href="route('password.request')"
                        :label="$t('Forgot your password?')">
                        {{ $t('Forgot your password?') }}
                    </AnchorLink>
                </div>

                <!-- Submit Button -->
                <button
                    type="submit"
                    class="w-full px-6 py-2 h-12 my-10 rounded-xl font-semibold uppercase text-xs text-white bg-gradient-to-r btn-sp-100"
                    :class="{'opacity-25': form.isSubmitting.value}"
                    :disabled="form.isSubmitting.value">
                    {{ $t('SIGN IN') }}
                </button>
                <div
                    v-if="form.isSubmitting.value"
                    class="mt-3 text-sm text-gray-400 text-center"
                    v-text="'Loading...'" />
            </form>

            <div class="mb-10 h-3 border-b-2 border-base-200 text-sm text-center text-base-300">
                <span class="bg-white px-5">or</span>
            </div>

            <!--or with Google-->

            <GoogleButton v-if="canSso && isAdminLogin" label="LOGIN WITH GOOGLE ACCOUNT" />

            <div class="text-sm uppercase font-semibold text-base-300 text-center mt-8">
                {{ $t('Don’t Have an account?') }}
                <AnchorLink class="text-primary" :inertia="true" :href="route('onboarding.index')">
                    {{ $t('Create a vendor account') }}
                </AnchorLink>
            </div>
        </section>
    </AuthLayout>
</template>
